var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-estado"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Actualizar Estado")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"estado"}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_estado.estado),expression:"form_estado.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_estado.estado.$invalid ? 'is-invalid' : 'is-valid',attrs:{"id":"estado"},on:{"input":function($event){return _vm.limpiarFechas()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_estado, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.$parent.listasForms.estados),function(estado){return [(
                    _vm.$store.getters.can(
                      'hidrocarburos.guias.estado' + estado.descripcion
                    )
                  )?_c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")]):_vm._e()]})],2),(!_vm.$v.form_estado.estado.required)?_c('div',{staticClass:"error"},[_vm._v(" Seleccione un estado ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificacion")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_estado.justificacion),expression:"form_estado.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_estado.justificacion.$invalid
                  ? 'is-invalid'
                  : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form_estado.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_estado, "justificacion", $event.target.value)}}}),(!_vm.$v.form_estado.justificacion.required)?_c('div',{staticClass:"error"},[_vm._v(" Ingrese una Justificacion ")]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form_estado.estado == 3),expression:"form_estado.estado == 3"}],staticClass:"form-group"},[_c('label',{attrs:{"for":"fecha_anulacion"}},[_vm._v("Fecha Anulación")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_estado.fecha_anulacion),expression:"form_estado.fecha_anulacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_estado.fecha_anulacion.$invalid
                      ? 'is-invalid'
                      : 'is-valid',staticStyle:{"font-size":"13px"},attrs:{"type":"date"},domProps:{"value":(_vm.form_estado.fecha_anulacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_estado, "fecha_anulacion", $event.target.value)}}})]),_c('div',{staticClass:"col-md-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_estado.hora_anulacion),expression:"form_estado.hora_anulacion"},{name:"mask",rawName:"v-mask",value:({
                    mask: '99:99:99',
                    hourFormat: '24',
                  }),expression:"{\n                    mask: '99:99:99',\n                    hourFormat: '24',\n                  }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_estado.hora_anulacion.$invalid
                      ? 'is-invalid'
                      : 'is-valid',staticStyle:{"font-size":"13px"},attrs:{"type":"text"},domProps:{"value":(_vm.form_estado.hora_anulacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_estado, "hora_anulacion", $event.target.value)}}})])]),(_vm.cargue_guia_anulada)?_c('div',[_c('div',{staticClass:"row justify-content-center"},[_c('h6',{staticClass:"text-danger mt-3"},[_vm._v(" Para anular esta guía deberá cargar el soporte de la misma. ")]),_c('div',{staticClass:"btn btn-default image-preview-input col-md-12",class:_vm.form_estado.file
                      ? 'border border-success'
                      : 'border border-warning'},[_c('input',{ref:"file",staticClass:"form-control-file",class:_vm.form_estado.file
                        ? 'btn btn-sm border border-success alert-default-success'
                        : 'btn btn-sm border border-warning alert-default-warning',staticStyle:{"min-width":"320px"},attrs:{"type":"file","id":"file","accept":".pdf, .PDF, .jpg, .JPG, .png, .PNG"},on:{"change":function($event){return _vm.elegirDirectorio()}}})])])]):_vm._e()])]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form_estado.$invalid),expression:"!$v.form_estado.$invalid"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.validar()}}},[_vm._v(" Guardar ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }