<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-estado">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualizar Estado</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="form_estado.estado"
                :class="
                  $v.form_estado.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
                @input="limpiarFechas()"
              >
                <option value="">Seleccione...</option>
                <template v-for="estado in $parent.listasForms.estados">
                  <option
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.guias.estado' + estado.descripcion
                      )
                    "
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </template>
              </select>
              <div class="error" v-if="!$v.form_estado.estado.required">
                Seleccione un estado
              </div>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificacion</label>
              <textarea
                v-model="form_estado.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.justificacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              ></textarea>
              <div class="error" v-if="!$v.form_estado.justificacion.required">
                Ingrese una Justificacion
              </div>
            </div>
            <div class="form-group" v-show="form_estado.estado == 3">
              <label for="fecha_anulacion">Fecha Anulación</label>
              <div class="row">
                <div class="col-md-7">
                  <input
                    type="date"
                    v-model="form_estado.fecha_anulacion"
                    style="font-size: 13px"
                    class="form-control form-control-sm"
                    :class="
                      $v.form_estado.fecha_anulacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div class="col-md-5">
                  <input
                    v-model="form_estado.hora_anulacion"
                    type="text"
                    class="form-control form-control-sm"
                    style="font-size: 13px"
                    v-mask="{
                      mask: '99:99:99',
                      hourFormat: '24',
                    }"
                    :class="
                      $v.form_estado.hora_anulacion.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
              <div v-if="cargue_guia_anulada">
                <div class="row justify-content-center">
                  <h6 class="text-danger mt-3">
                    Para anular esta guía deberá cargar el soporte de la misma.
                  </h6>

                  <div
                    class="btn btn-default image-preview-input col-md-12"
                    :class="
                      form_estado.file
                        ? 'border border-success'
                        : 'border border-warning'
                    "
                  >
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      class="form-control-file"
                      accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                      style="min-width: 320px;"
                      :class="
                        form_estado.file
                          ? 'btn btn-sm border border-success alert-default-success'
                          : 'btn btn-sm border border-warning alert-default-warning'
                      "
                      @change="elegirDirectorio()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_estado.$invalid"
              @click="validar()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "GuiaEstado",
  data() {
    return {
      form_estado: {
        estadoAnt: null,
        estado: null,
        justificacion: null,
        fecha_anulacion: null,
        hora_anulacion: null,
        file: null,
      },
      cargue_guia_anulada: false,
      sitio_id: null,
    };
  },
  validations() {
    let fecha_anulacion = {};
    let hora_anulacion = {};
    let file = {};
    file = this.cargue_guia_anulada ? { required } : { required: false };

    if (this.form_estado.estado != 3) {
      fecha_anulacion = {
        required: false,
      };

      hora_anulacion = {
        required: false,
      };
    } else {
      fecha_anulacion = {
        required,
      };

      hora_anulacion = {
        required,
      };
    }

    return {
      form_estado: {
        estado: {
          required,
        },
        justificacion: {
          required,
          minLength: minLength(10),
        },
        fecha_anulacion: fecha_anulacion,
        hora_anulacion: hora_anulacion,
        file: file,
      },
    };
  },
  methods: {
    async validar() {
      let self = this;
      if (!self.$v.form_estado.$invalid) {
        if (self.form_estado.estadoAnt == 2 && self.form_estado.estado == 8) {
          self.$swal({
            icon: "error",
            title:
              "Las guias con estado usado no pueden cambiarse a estado de cambio",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          let data = self.form_estado;
          //Se valida si se quiere anular la guía
          if (self.form_estado.estado == 3) {
            //Se valida que no existan guias anuladas sin escaner de guía para el mismo sitio de la guía que se quiere anular
            data.sitio_id = self.sitio_id;
            await axios({
              method: "post",
              url: "/api/hidrocarburos/guias/validarAnualcion",
              data: data,
            }).then(async (response) => {
              self.cargue_guia_anulada = await response.data;
              if (self.cargue_guia_anulada == false) {
                self.$swal({
                  icon: "error",
                  title:
                    "No es posible anular esta guía ya que existen guías anuladas sin escaner de guía en el mismo sitio de esta guía.",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
              let formData = new FormData();
              //Se valida que se le haya cargado el escaner de la guía que se quiere anular
              if (self.cargue_guia_anulada && self.form_estado.file) {                                  
                formData.append(
                  "name_pdf",
                  self.form_estado.file.name.replace(/\.[^/.]+$/, "")
                );
                formData.append("file", self.form_estado.file);
                formData.append(
                  "ext",
                  "." + self.form_estado.file.name.split(".").pop()
                );
                formData.append("estadoAnt", self.form_estado.estadoAnt);
                formData.append("estado", self.form_estado.estado);
                formData.append(
                  "justificacion",
                  self.form_estado.justificacion
                );
                formData.append(
                  "fecha_anulacion",
                  self.form_estado.fecha_anulacion
                );
                formData.append(
                  "hora_anulacion",
                  self.form_estado.hora_anulacion
                );
                await self.save(formData);
              }
            });
          } else {
            await self.save(data);
          }
        }
      }
    },

    async save(data) {
      let self = this;
      await axios({
        method: "post",
        url: "/api/hidrocarburos/guias/update/" + self.form_estado.id,
        data: data,
      })
        .then(async (response) => {
          await self.limpiarForm();
          self.$refs.closeModal.click();
          self.$parent.getIndex();
          self.$swal({
            icon: "success",
            title: "Se realizo el cambio de estado exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          self.cargando = false;
          //Muestra error si el nombre del escaner(PDF-IMG) NO coincide con el número de la guía que se quiere anular
          if (e.response && e.response.status == 404) {
            self.$swal({
              icon: "error",
              title: e.response.data.mensaje,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    llenar_modal(id, val_ant, sitio_id) {
      this.limpiarForm();
      this.form_estado.file = null;
      this.form_estado.id = id;
      this.form_estado.estadoAnt = val_ant;
      this.sitio_id = sitio_id;
    },

    async limpiarForm() {
      this.form_estado.estadoAnt = null;
      this.form_estado.estado = null;
      this.form_estado.justificacion = null;
      this.form_estado.fecha_anulacion = null;
      this.form_estado.hora_anulacion = null;
      this.cargue_guia_anulada = false;
      const fileInput = document.getElementById("file");
      fileInput.value = null;
      this.form_estado.file = null;
    },

    limpiarFechas() {
      this.form_estado.fecha_anulacion = null;
      this.form_estado.hora_anulacion = null;
    },

    elegirDirectorio() {
      this.form_estado.file = this.$refs.file.files[0];
    },
  },
};
</script>
